<template>
  <div id="courseLessons">
    <v-container fluid class="my-12">
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-expansion-panels v-model="panel" multiple popout>
            <v-expansion-panel v-for="(item, i) in lessons" :key="i">
              <v-expansion-panel-header class="title" disable-icon-rotate>
                <div>
                  <span class="d-block subtitle-2 grey--text">
                    Modül
                    {{
                      10 > item.rowNumber
                        ? "0" + item.rowNumber
                        : item.rowNumber
                    }}:
                  </span>
                  {{ item.title }}
                </div>
                <template v-slot:actions>
                  <v-icon v-if="item.isCompleted" color="primary"
                    >mdi-check</v-icon
                  >
                  <v-icon v-if="!item.isCompleted" color="grey"
                    >mdi-check</v-icon
                  >
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list color="transparent" rounded>
                  <v-list-item
                    v-for="(subitem, j) in item.items"
                    :key="j"
                    @click="selectItem(i, j)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="subitem.icon" color="primary" size="40" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="subitem.title" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular
                        size="32"
                        width="3"
                        :value="subitem.progress"
                        color="secondary"
                        v-if="subitem.type == 'video'"
                      >
                        <span
                          v-if="subitem.progress < 100"
                          style="font-size: 0.6rem;"
                          >{{ subitem.progress }}</span
                        >
                        <v-icon
                          v-if="subitem.progress === 100"
                          color="primary"
                          small
                          >mdi-check</v-icon
                        >
                      </v-progress-circular>
                      <v-progress-circular
                        size="32"
                        width="3"
                        :value="subitem.progress"
                        color="secondary"
                        v-if="subitem.type == 'document'"
                      >
                        <v-icon
                          :color="
                            subitem.progress === 0
                              ? 'grey lighten-1'
                              : 'primary'
                          "
                          small
                          >mdi-check</v-icon
                        >
                      </v-progress-circular>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="!item.isCompleted" @click="takeExam(i)">
                    <v-list-item-icon>
                      <v-icon color="primary" size="40"
                        >mdi-checkbox-multiple-marked-outline</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Tamamlama Sınavı</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card :color="dialogBgColor" tile>
              <v-toolbar :dark="dialogDark" color="transparent" elevation="0">
                <v-btn icon :dark="dialogDark" @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ selectedItem.title }}</v-toolbar-title>
              </v-toolbar>

              <lesson-video
                v-if="selectedItem.type == 'video'"
                :video="selectedItem"
              />

              <lesson-document
                v-if="selectedItem.type == 'document'"
                :document="selectedItem"
              />

              <lesson-quiz
                v-if="selectedItem.type == 'quiz'"
                :lesson="selectedLesson"
                :course="course"
                @close="closeDialog"
              />
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import LessonVideo from "./LessonVideo.vue";
import LessonDocument from "./LessonDocument.vue";
import LessonQuiz from "./LessonQuiz.vue";

export default Vue.extend({
  metaInfo: () => ({
    title: "Konular"
  }),
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lessons: [
        {
          id: "",
          rowNumber: 1,
          title: "",
          items: [
            {
              id: "",
              rowNumber: 1,
              type: "",
              icon: "",
              title: "",
              progress: 0
            }
          ],
          isCompleted: false
        }
      ],
      panel: [0],
      dialog: false,
      dialogBgColor: "#000",
      dialogDark: true,
      selectedLesson: {},
      selectedItem: {}
    };
  },
  computed: {
    courseUrlCode() {
      return this.$route.params.courseUrlCode;
    },
    videoId() {
      return this.$route.params.id;
    }
  },
  methods: {
    getModules() {
      this.lessons = [];

      // Modülleri al
      firebase
        .firestore()
        .collection("lessons")
        .where("courseId", "==", this.course.id)
        .orderBy("rowNumber")
        .get()
        .then(qs1 => {
          qs1.forEach(lesson => {
            // Modül bilgileri
            const newLesson = {
              id: lesson.id,
              rowNumber: lesson.data().rowNumber,
              title: lesson.data().title,
              items: [],
              isCompleted: false
            };

            // Modüle eklenmiş öğeleri al
            firebase
              .firestore()
              .collection("lessonItems")
              .where("courseId", "==", this.course.id)
              .where("lessonId", "==", lesson.id)
              .orderBy("rowNumber")
              .get()
              .then(qs2 => {
                qs2.forEach(item => {
                  const newItem = item.data();

                  newItem.id = item.id;
                  newItem.progress = 0;

                  newLesson.items.push(newItem);
                });
              });

            this.lessons.push(newLesson);
          });
        });
    },
    selectItem(i, j) {
      this.selectedLesson = this.lessons[i];
      this.selectedItem = this.lessons[i].items[j];
      this.dialog = true;
    },
    takeExam(i) {
      this.selectedLesson = this.lessons[i];

      const moduleNumber =
        10 > this.selectedLesson.rowNumber
          ? "0" + this.selectedLesson.rowNumber
          : this.selectedLesson.rowNumber;

      this.selectedItem = {
        title: `Modül ${moduleNumber}: Tamamlama Sınavı`,
        type: "quiz"
      };

      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    closeQuiz() {
      this.dialog = false;
      this.getModules();
    }
  },
  mounted() {
    this.getModules();
  },
  components: {
    LessonVideo,
    LessonDocument,
    LessonQuiz
  },
  watch: {
    dialog: function(newDialog) {
      if (newDialog == false) {
        this.selectedLesson = {};
        this.selectedItem = {};
      } else {
        if (this.selectedItem.type === "quiz") {
          this.dialogBgColor = "#545454";
        } else {
          this.dialogBgColor = "#000";
          this.dialogDark = true;
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
#courseLessons {
  .lesson-card {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all ease 0.3s;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      transform: scale(1.04);
    }
  }

  .v-expansion-panel {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px;
    background-color: #f1f1f1;

    &::before {
      box-shadow: none;
      border: none 0 transparent;
    }

    &::after {
      box-shadow: none;
      border: none 0 transparent;
    }
  }
}
</style>
