




import Vue from "vue";
import VimeoPlayer from "@vimeo/player";

export default Vue.extend({
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      player: {} as VimeoPlayer
    };
  },
  mounted() {
    this.installPlayer();
  },
  methods: {
    async installPlayer() {
      this.player = new VimeoPlayer("player", {
        id: this.video.vimeoId
      });
    }
  }
});
