<template>
  <v-container class="fill-height">
    <v-row no-gutters>
      <v-col>
        <div class="document">
          <iframe :src="documentSrc" frameborder="0"></iframe>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  computed: {
    documentSrc() {
      return this.document.fileUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.document {
  height: 100%;

  iframe {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    border: none 0 transparent;
  }
}
</style>
